import React, { Component } from "react";
import LandingScreen from "./marketPlace/screens/landing/view/LandingScreen.js";

export default class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <LandingScreen />;
  }
}
