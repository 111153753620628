import React, { Component } from "react";
import "../../common/css/Common.css";
import "../css/Landing.css";

import CommonComponentManager from "../../common/managers/ComponentManager";

export default class ComponentManager {
  static getInfoGraphicsBanner(
    title,
    subTitle,
    img,
    getStartedCallback,
    signupCallback
  ) {
    return (
      <div style={{ marginTop: -85 }}>
        <table>
          <tr>
            <td style={{ width: "50%" }}>
              {ComponentManager.getTagLine(
                title,
                subTitle,
                getStartedCallback,
                signupCallback
              )}
            </td>
            <td style={{ width: "50%" }}>
              {ComponentManager.getLangingImage(img)}
            </td>
          </tr>
        </table>
      </div>
    );
  }

  static getTagLine(title, subTitle, getStartedCallback, signupCallback) {
    return (
      <div style={{ maxWidth: "100%" }}>
        <h3 className="TagLine">{title}</h3>
        <div style={{ marginTop: -40 }}>
          <subText style={{ color: "gray", fontSize: 18 }}>{subTitle}</subText>
        </div>
        <div style={{ marginTop: 30 }}>
          {CommonComponentManager.getGradientButton(
            "Get Started!",
            "",
            getStartedCallback
          )}
          {CommonComponentManager.getBorderedButton(
            "Sign up",
            "",
            signupCallback
          )}
        </div>
      </div>
    );
  }

  static getLangingImage(img) {
    return <img src={img} alt="" style={{ maxWidth: "100%" }} />;
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  static getCardView(cardData, callback) {
    return (
      <div
        className="Card"
        onClick={() => {
          callback(cardData);
        }}
      >
        <img src={cardData.img} alt="" className="CardLogo"></img>
        <div className="div_CardTitle">
          <h3>{cardData.appTitle}</h3>
        </div>
        <div className="div_CardSubTitle">
          <text className="CardSubTitle">{cardData.category}</text>
        </div>
        <div className="div_CardDescription">
          <text className="CardDescription">{cardData.shortDescription}</text>
        </div>
        {CommonComponentManager.getStarRatingView(
          cardData.rating,
          cardData.noOfReviews,
          cardData.price
        )}
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  static getSerachBar(searchText, searchTextChangeCallback, searchCallback) {
    return (
      <div className="LandingSearchBar">
        {ComponentManager.getSearchBarSection(
          "EXPLORE APPS",
          "Explore our accounting softwares for small business."
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {ComponentManager.getSearchSection(
            "SEARCH",
            "Search all applications",
            searchText,
            searchTextChangeCallback
          )}
          {CommonComponentManager.getGradientButton(
            "Search",
            "",
            searchCallback
          )}
        </div>
      </div>
    );
  }
  static getSearchBarSection(title, subTitle) {
    return (
      <div className="SearchBarLeftSection">
        <div>
          <text style={{ fontSize: 11 }}>{title}</text>
        </div>
        <div style={{ marginTop: 4 }}>
          <subText style={{ fontSize: 14, color: "gray" }}>{subTitle}</subText>
        </div>
      </div>
    );
  }
  static getSearchSection(
    title,
    subTitle,
    searchText,
    searchTextChangeCallback
  ) {
    return (
      <div style={{ width: "100%", paddingLeft: 15 }}>
        <div>
          <text style={{ fontSize: 11 }}>{title}</text>
        </div>
        <div style={{ marginTop: 4 }}>
          {/* <subText style={{ fontSize: 14, color: "gray" }}>{subTitle}</subText> */}
          <input
            type="text"
            placeholder={subTitle}
            value={searchText}
            onChange={(event) => searchTextChangeCallback(event)}
          />
        </div>
      </div>
    );
  }
  static getSearchBarVerticalLine() {
    return (
      <div
        style={{
          width: 1,
          height: "60%",
          backgroundColor: "rgba(230, 230, 230, 1)",
        }}
      ></div>
    );
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  static getVerticalAppsMenu(
    verticalMenuData,
    selectedGroup,
    titleCallback,
    callback
  ) {
    return (
      <div
        className="leftMenu"
        style={{
          backgroundColor: "",
          // paddingLeft: "0%",
          marginTop: 60,
          overflow: "hidden",
        }}
      >
        <h4 className="all-app-title" onClick={() => titleCallback()}>
          All Apps
        </h4>
        {this.getAllVerticalMenuItems(
          verticalMenuData,
          selectedGroup,
          callback
        )}

        <div style={{ height: 40 }} />
        {/* <h4>Collections</h4> */}
      </div>
    );
  }
  static getAllVerticalMenuItems(verticalMenuData, selectedGroup, callback) {
    let padding = 15;
    let fontSize = 14;
    let groupClass = "";
    var arr = [];
    for (let i = 0; i < verticalMenuData.length; i++) {
      groupClass =
        selectedGroup.id == verticalMenuData[i].id ? "selectedGroup" : "";
      arr.push(
        this.getVerticalMenuItem(
          verticalMenuData[i],
          padding,
          fontSize,
          groupClass,
          callback
        )
      );
    }
    return arr;
  }
  static getVerticalMenuItem(group, padding, fontSize, groupClass, callback) {
    return (
      <div
        className={groupClass}
        style={{ padding: padding, cursor: "pointer" }}
        onClick={() => callback(group)}
      >
        <subText style={{ fontSize: fontSize }} className="groupClassWrapper">
          {group.name}
        </subText>
      </div>
    );
  }
  static getAppListSection(cardsData, selectedGroup) {
    let groupName = "Featured Apps";
    let groupDesc =
      "Explore the most installed apps in the Deskera market place.";
    if (selectedGroup.name) {
      groupName = selectedGroup.name;
      groupDesc = selectedGroup.shortDescription;
    }
    return (
      <div style={{ paddingTop: 60 }}>
        {ComponentManager.getAppSection(groupName, groupDesc, cardsData)}
      </div>
    );
  }

  static getAppSection(title, subTitle, cardsData) {
    return (
      <div>
        <text className="AppSectionTitle">{title}</text>
        <div style={{ height: 5 }} />
        <subText className="AppSectionSubTitle">{subTitle}</subText>
        <div className="CardsHolder">{cardsData}</div>
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  static getBecomeAParterBanner() {
    return (
      <div className="BecomeParter">
        <text style={{ fontSize: 33 }}>Want to feature your apps here?</text>{" "}
        <subText style={{ fontSize: 15, marginLeft: 10 }}>
          Become an app partner with us.
        </subText>
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  static getBottomLogo() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: 20,
          flexWrap: "wrap",
        }}
      >
        <img
          src={require("../../../assets/logo.png")}
          alt=""
          style={{ width: 130, height: 45 }}
        />
        {/* <text style={{ fontSize: 16, marginLeft: 2, paddingTop: 20 }}>
          App Marketplace
        </text> */}
        <subText
          style={{
            fontSize: 14,
            marginLeft: 0,
            color: "gray",
            paddingTop: 20,
          }}
        >
          The best place to find apps that extends and integrate with Deskera.
        </subText>
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  static getBottomMenus(callback, appStoreCallback, playStoreCallback) {
    return (
      <div className="BottomMenuHolder">
        {/* {ComponentManager.getBottomMenu(
          "About us",[],
          // [
          //   "Team",
          //   "Governance",
          //   "Sponsorship",
          //   "Security",
          //   "Social/enhironmental impact",
          //   "Contact us",
          // ],
          callback
        )}
        {ComponentManager.getBottomMenu(
          "Investor",[],
          // [
          //   "Stock price",
          //   "Financial information",
          //   "Market announcements",
          //   "Leadership",
          //   "FAQ",
          // ],
          callback
        )}
        {ComponentManager.getBottomMenu(
          "Media",[],
          // ["Factsheet", "Announcements", "Download", "Contacts"],
          callback
        )} */}
        {ComponentManager.getBottomLogo()}
        {ComponentManager.getBottomMenuAddress(
          "Know more",
          appStoreCallback,
          playStoreCallback
        )}
      </div>
    );
  }
  static getBottomMenuAddress(title, appStoreCallback, playStoreCallback) {
    return (
      <div style={{ width: 290 }}>
        <text style={{ fontSize: 16 }}>{title}</text>
        <div style={{ marginTop: 10 }} />
        <subText style={{ fontSize: 13, color: "gray" }}>
          39 Robinson Road,
          <br />
          Robinson Point,
          <br />
          Singapore 068911.
        </subText>
        <div style={{ height: 20 }} />
        <img
          src={require("../../../assets/appStoreButton_1.png")}
          alt=""
          onClick={() => appStoreCallback()}
          className="download-store"
          style={{ width: "55%", borderRadius: 6 }}
        />
        <div style={{ height: 10 }} />
        <img
          src={require("../../../assets/appStoreButton_2.png")}
          alt=""
          className="download-store"
          onClick={() => playStoreCallback()}
          style={{ width: "55%", borderRadius: 6 }}
        />
      </div>
    );
  }
  static getBottomMenu(title, items, callback) {
    return (
      <div>
        <text style={{ fontSize: 16 }}>{title}</text>
        <div style={{ marginTop: 10 }} />
        {ComponentManager.getBottomMenuItems(items, callback)}
      </div>
    );
  }
  static getBottomMenuItems(items, callback) {
    let padding = 5;
    let fontSize = 14;
    let color = "gray";
    var arr = [];
    for (let i = 0; i < items.length; i++) {
      arr.push(
        ComponentManager.getVerticalMenuItem(
          items[i],
          padding,
          fontSize,
          callback,
          color
        )
      );
    }
    return arr;
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  static getShareOptionsView() {
    return (
      <div
        style={{
          opacity: 0.3,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 50,
        }}
      >
        <img
          src={require("../../../assets/ic_shareOptions.png")}
          alt=""
          style={{ width: "16%" }}
        />
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}
