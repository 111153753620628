import React from "react";
import "../../common/css/Common.css";
import CommonComponentManager from "../../common/managers/ComponentManager";
import "../css/ProductDetails.css";


export default class ComponentManager {
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  static getScreenHeader(selectedApp, productDetails) {
    let styleObj = { marginTop: 15, display: "flex", flexDirection: "row" };
    return (
      <div>
        <div style={{ display: "flex", justifyItems: "center" }}>
          <img
            //src={selectedApp.img}
            src={productDetails.imageUrl}
            alt=""
            style={{ height: 35, marginRight: 8 }}
          />
          {/* <subText style={{ fontSize: 25, color: "black", fontWeight: "bold" }}>
            {selectedApp.appTitle}
          </subText> */}
        </div>
        <div style={{ marginTop: 5 }}>
          <subText style={{ fontSize: 14, color: "gray" }}>
            {selectedApp.category}
          </subText>
        </div>
        <div style={{ width: 130, marginTop: -5 }}>
          {CommonComponentManager.getStarRatingInDetailView(
            selectedApp.rating,
            selectedApp.noOfReviews,
            selectedApp.price,
            styleObj
          )}
        </div>
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  static getStoreImageSectionView(img, arr_storeImages, callback) {
    return (
      <div>
        <div className="StoreImage">
          <img src={img} alt="" style={{ width: "100%", height: "100%" }} />
        </div>
        <div className="StoreThumbHolder">
          {ComponentManager.getStoreThumbnailView(arr_storeImages, callback)}
        </div>
      </div>
    );
  }

  static getStoreThumbnailView(arr_storeImages, callback) {
    var arr = [];
    if (arr_storeImages && arr_storeImages.length > 0) {
      for (let i = 0; i < arr_storeImages.length; i++) {
        arr.push(
          ComponentManager.getStoreThumbImage(arr_storeImages[i], i, callback)
        );
      }
    }
    return arr;
  }
  static getStoreThumbImage(img, index, callback) {
    return (
      <img
        className="StoreThumb"
        onClick={() => {
          callback(index);
        }}
        src={img}
        alt=""
      />
    );
  }

  static getComingSoonButton(title, width = "") {
    return (
      <button className="ComingSoonButton" style={{ width: width }}>
        {title}
      </button>
    );
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  static getProductInfoView(
    productDetails,
    getThisAppCallback,
    exploreFeaturesCallback,
    appAlreadyConnected
  ) {
    let buttonText = appAlreadyConnected ? "Open" : "Get App";
    return (
      <div className="InfoView">
        <subText style={{ fontSize: 14, fontWeight: "bold" }}>
          {productDetails.productCategory}
        </subText>
        <div />
        <subText style={{ fontSize: 12, color: "gray" }}>
          Available for download with Deskera.
        </subText>
        <div style={{ height: 10 }} />
        {productDetails.setupLink
          ? CommonComponentManager.getGradientButton(
              buttonText,
              "100%",
              getThisAppCallback
            )
          : ComponentManager.getComingSoonButton("Coming Soon", "100%")}
        <div style={{ height: 10 }} />
        {/* {CommonComponentManager.getBorderedButton(
          "Explore all features",
          "100%",
          exploreFeaturesCallback
        )} */}
        <div style={{ height: 15 }} />
        <subText style={{ fontSize: 12, color: "gray" }}>
          Made by {productDetails.createdBy}
          <br />
          Added in {productDetails.createdAt}
        </subText>
        <div style={{ height: 25 }} />
        <div
          style={{
            height: 2,
            width: "100%",
            backgroundColor: "gray",
            opacity: 0.1,
          }}
        />
        {/* {ComponentManager.getProductInfoSection("Countries", countries)}
        {ComponentManager.getProductInfoSection("Languages", languagues)} */}
        {ComponentManager.getProductInfoSection(
          "Support",
          productDetails.supportEmail
        )}
      </div>
    );
  }

  static getProductInfoSection(title, description) {
    return (
      <div style={{ marginTop: 25 }}>
        <subText style={{ fontSize: 12, color: "black", fontWeight: "bold" }}>
          {title}
          <br />
        </subText>
        <subText style={{ fontSize: 12, color: "gray" }}>{description}</subText>
      </div>
    );
  }

  static markup(desc) {
    // desc = desc.substring(1, desc.length - 1);
    return { __html: desc };
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  static getProductDescriptionView(productName, about, aboutWithDeskera) {
    return (
      <div>
        <div dangerouslySetInnerHTML={ComponentManager.markup(about)}></div>
        {/* <div className="ProductInfoText">
          <subText style={{ fontSize: 20, color: "black", fontWeight: "bold" }}>
            About
            <br />
          </subText>
          <div style={{ height: 10 }} />
          <subText>
            {about}
            <ul>
              <li>
                Reduce late or missed payments by having customers schedule
                recurring payments
              </li>
              <li>
                Simplify collections with payments deposited to your bank
                account directly
              </li>
              <li>
                Automatic reconciliation within your Deskera account with this
                integration
              </li>
            </ul>
          </subText>
        </div> */}
        {/* <div
          className="ProductInfoText"
          style={{
            marginTop: 50,
          }}
        >
          <subText
            style={{
              fontSize: 20,
              color: "black",
              fontWeight: "bold",
              textAlign: "justify",
              width: "100%",
            }}
          >
            {productName} + Deskera
            <br />
          </subText>
          <div style={{ height: 10 }} />
          <subText>{aboutWithDeskera}</subText>
        </div> */}
        <div style={{ height: 50 }} />
        <subText style={{ fontSize: 12, color: "lightGray" }}>
          Although Deskera reviews each app in our App Marketplace, we can’t
          give any guarantees. It’s up to you to assess the performance, quality
          and suitability of any app before going ahead.
        </subText>
      </div>
    );
  }
}
