import React, { Component } from "react";
import "../../common/css/Common.css";
import "../css/Landing.css";

import ComponentManager from "../managers/ComponentManager";
import CommonComponentManager from "../../common/managers/ComponentManager";
import ProductDetailsScreen from "../../productDetails/view/ProductDetailsScreen";
import {
  getAllApps,
  getAllGroups,
  searchApps,
  getAppsByGroup,
  getAllConnectedApps,
} from "../clientApi/LandingScreen";
import { links } from "../../../../Constants";

export default class LandingScreen extends Component {
  arr_headerMenuItems = ["Contact Us"];

  constructor() {
    super();

    this.state = {
      productPopupRequired: true,
      transparentBackgroundVisibility: false,
      productDetailsScreenVisibility: false,
      searchAppLogoRequired: false,
      searchText: "",
      // arr_card: this.arr_card,
      arr_card: [],
      arr_verticalMenu: [],
      selectedGroup: {},
      selectedApp: {},
      productTitle: "",
      productCategory: "",
      productShortDescription: "",
      ratings: 0,
      noOfReviews: 0,
      tagText: "",
      productIcon: "",
      connectedApps: [],
    };
  }

  componentDidMount() {
    const { selectedGroup } = this.state;
    window.addEventListener("scroll", this.onScroll);
    getAllGroups().then((groups) =>
      this.setState({ arr_verticalMenu: groups })
    );
    getAllApps(selectedGroup).then((apps) =>
      this.setState({ arr_card: apps, selectedGroup: {} })
    );
    getAllConnectedApps().then((connectedApps) =>
      this.setState({ connectedApps: connectedApps })
    );
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    // window.pageYOffset
  };

  render() {
    const {
      productPopupRequired,
      productDetailsScreenVisibility,
      transparentBackgroundVisibility,
      arr_verticalMenu,
    } = this.state;
    return (
      <div className="container">
        {productPopupRequired &&
          productDetailsScreenVisibility &&
          this.getProductDetailsView()}
        {productPopupRequired &&
          transparentBackgroundVisibility &&
          this.getTransparentBackground()}
        <div className="container2">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {CommonComponentManager.getAppLogo(45, 0)}
            <text style={{ fontSize: 14, marginTop: 5 }}>App Marketplace</text>
          </div>
          {this.getMiddleBody(
            this.getAllCards(),
            arr_verticalMenu,
            this.searchTextChanged,
            this.searchAppTapped
          )}
          {ComponentManager.getBecomeAParterBanner()}
          {/* {ComponentManager.getBottomLogo()} */}
          {ComponentManager.getBottomMenus(
            this.bottomMenuItemTapped,
            this.appStoreTapped,
            this.playStoreTapped
          )}
          {/* {ComponentManager.getShareOptionsView()} */}

          <div style={{ height: 100 }} />
        </div>
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  getMiddleBody(
    cardsData,
    verticalMenuData,
    searchTextChangeCallback,
    searchCallback
  ) {
    const { searchtext, selectedGroup } = this.state;
    return (
      <div
        style={{
          backgroundColor: "rgb(242, 242, 242)",
          borderRadius: 15,
          padding: 30,
          marginTop: 15,
          boxSizing: "border-box",
        }}
      >
        {ComponentManager.getSerachBar(
          searchtext,
          searchTextChangeCallback,
          searchCallback
        )}
        <div style={{ marginTop: -10 }}>
          <table style={{ width: "100%", boxSizing: "border-box" }}>
            <tr>
              <td valign="top" className="leftMenuHolder">
                {ComponentManager.getVerticalAppsMenu(
                  verticalMenuData,
                  selectedGroup,
                  this.allAppItemTapped,
                  this.verticalMenuItemTapped
                )}
              </td>
              <td valign="top" className="appListholder">
                {ComponentManager.getAppListSection(cardsData, selectedGroup)}
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  getStartedTapped = () => {
    window.open(links.GETTING_STARTED);
  };
  signupTapped = () => {
    window.open(links.SIGN_UP);
  };

  headerMenuItemTapped = (title) => {
    switch (title) {
      case "About Us":
        window.open(links.ABOUT_US);
        break;
      case "Investor":
        window.open("");
        break;
      case "Our team":
        window.open(links.ABOUT_US);
        break;
      case "Contact Us":
        window.open(links.CONTACT_US);
        break;
    }
  };

  bottomMenuItemTapped = (title) => {
    alert("Bottom menu tapped: " + title);
  };
  appStoreTapped = () => {
    window.open(links.APP_STORE);
  };
  playStoreTapped = () => {
    window.open(links.GOOGLE_PLAY);
  };
  allAppItemTapped = () => {
    const { selectedGroup } = this.state;
    getAllApps(selectedGroup).then((apps) =>
      this.setState({ arr_card: apps, selectedGroup: {} })
    );
  };
  searchAppTapped = () => {
    let { searchText } = this.state;
    searchApps(searchText).then((apps) => this.setState({ arr_card: apps }));
  };
  searchTextChanged = (event) => {
    this.setState({ searchText: event.currentTarget.value });
  };
  verticalMenuItemTapped = (groupSelected) => {
    getAppsByGroup(groupSelected).then((apps) =>
      this.setState({ arr_card: apps, selectedGroup: groupSelected })
    );
  };
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  getProductDetailsView() {
    const { selectedApp, connectedApps } = this.state;
    let appAlreadyConnected = false;
    connectedApps.forEach((obj, index) => {
      if (connectedApps[index].id === selectedApp.id) {
        appAlreadyConnected = true;
      }
    });
    return (
      <div className="ProductDetailsHolder">
        <ProductDetailsScreen
          selectedApp={selectedApp}
          appAlreadyConnected={appAlreadyConnected}
          onClose={this.hideProductDetailsView}
        />
      </div>
    );
  }

  showProductDetailsView = (cardData) => {
    this.setState({
      selectedApp: cardData,
      transparentBackgroundVisibility: true,
      productDetailsScreenVisibility: true,
    });
  };
  hideProductDetailsView = () => {
    this.setState({
      transparentBackgroundVisibility: false,
      productDetailsScreenVisibility: false,
    });
  };

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  getTransparentBackground() {
    return (
      <div
        className="TransparentBackground"
        onClick={() => {
          this.hideProductDetailsView();
        }}
      />
    );
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  getAllCards() {
    const { arr_card } = this.state;
    var arr = [];
    for (let i = 0; i < arr_card.length; i++) {
      let cardData = arr_card[i];
      arr.push(this.getCardView(cardData));
    }
    return arr;
  }

  getCardView(cardData) {
    return ComponentManager.getCardView(cardData, this.showProductDetailsView);
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}
