import React, { Component } from "react";
import "../css/Common.css";
import "../../landing/css/Landing.css";
import star_4_8 from '../../../assets/star_4_8.svg';
import lp_4_point_5_star from '../../../assets/lp_4_point_5_star.svg';
import lp_4_star from '../../../assets/lp_4_star.svg';
import lp_4_2_star from '../../../assets/lp_4_2_star.svg';
import lp_4_4_star from '../../../assets/lp_4_4_star.svg';
import lp_5_star from '../../../assets/lp_5_star.svg';
import lp_4_6_star from '../../../assets/lp_4_6_star.svg';
import star_0 from '../../../assets/star_0.svg'

export default class ComponentManager {
  static getGradientButton(title, width = "", callback) {
    return (
      <button
        onClick={callback}
        className="GradientButton"
        style={{ width: width }}
      >
        {title}
      </button>
    );
  }
  static getBorderedButton(title, width = "", callback) {
    return (
      <button
        onClick={callback}
        className="BorderedButton"
        style={{ width: width, backgroundColor: "transparent" }}
      >
        {title}
      </button>
    );
  }

  static getStarRatingView(ratings, noOfReviews, tagText) {
    return (
      <div
        className="flex-container"
        style={{
          position: "absolute",
          bottom: 30,
          left: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          right: 20,
        }}
      >
        <div>
          {ComponentManager.getRatedStars(ratings)}
          {ComponentManager.getNotRatedStars(ratings)}
          <text style={{ color: "LightGray", fontSize: 12 }}>
            {" "}
            ({noOfReviews})
          </text>
        </div>
        <div>
          <text
            style={{
              color: "rgb(94, 94, 94)",
              fontSize: 11,
              padding: 5,
              backgroundColor: "rgb(240, 240, 240)",
              borderRadius: 6,
            }}
          >
            {tagText}
          </text>
        </div>
      </div>
    );
  }

  static getStarRatingInDetailView(ratings, noOfReviews, tagText) {
    return (
      <div
        className="flex-container"
        style={{ marginTop: 15, display: "flex", flexDirection: "row" }}
      >
        <div style={{ width: "100%" }}>
          {ComponentManager.getRatedStars(ratings)}
          {ComponentManager.getNotRatedStars(ratings)}
          <text style={{ color: "LightGray", fontSize: 12 }}>
            {" "}
            ({noOfReviews})
          </text>
        </div>
        <div style={{ width: "16%" }}>
          <text
            style={{
              color: "rgb(94, 94, 94)",
              fontSize: 11,
              padding: 5,
              backgroundColor: "rgb(240, 240, 240)",
              borderRadius: 6,
            }}
          >
            {tagText}
          </text>
        </div>
      </div>
    );
  }

  static getRatedStars(ratings) {
    console.log("getRatedStars")
    var arr = [];
    //<img src={image} style={{ height: 30 }} alt="" />

    //  console.log("getRatedStarsloop")
    if(ratings==="0" || ratings===null)
    arr.push(<img src={star_0}  />);
     else if(ratings>=4 && ratings<4.2)
      arr.push(<img src={lp_4_star}  />); 
      else if(ratings>=4.2 && ratings<4.4)
     arr.push(<img src={lp_4_2_star}  />); 
      else if(ratings>=4.4 && ratings<4.5)
     {
        console.log("Svg running 4 times");
     arr.push(<img src={lp_4_4_star}  />);
     }
     else if(ratings>=4.5 && ratings<4.6)
     arr.push(<img src={lp_4_point_5_star}  />)
    else if(ratings>=4.6 && ratings<4.8)
    {
      arr.push(<img src={lp_4_6_star}/>)
    // arr.push(<svg xmlns="http://www.w3.org/2000/svg" width="85.51" height="15.161" viewBox="0 0 85.51 15.161"><defs><style>.a{fill:#f80;}.b{fill:rgba(0,0,0,0.1);}</style></defs><path class="a" d="M7.581,0,9.438,5.693l5.723.1L10.586,9.408l1.68,5.754L7.581,11.7,2.9,15.161l1.68-5.754L0,5.791l5.723-.1Z" transform="translate(0)"/><path class="a" d="M7.581,0,9.438,5.693l5.723.1L10.586,9.408l1.68,5.754L7.581,11.7,2.9,15.161l1.68-5.754L0,5.791l5.723-.1Z" transform="translate(17.587)"/><path class="a" d="M7.581,0,9.438,5.693l5.723.1L10.586,9.408l1.68,5.754L7.581,11.7,2.9,15.161l1.68-5.754L0,5.791l5.723-.1Z" transform="translate(35.175)"/><path class="a" d="M7.581,0,9.438,5.693l5.723.1L10.586,9.408l1.68,5.754L7.581,11.7,2.9,15.161l1.68-5.754L0,5.791l5.723-.1Z" transform="translate(52.762)"/><g transform="translate(70.349)"><path class="b" d="M7.581,0,9.438,5.693l5.723.1L10.586,9.408l1.68,5.754L7.581,11.7,2.9,15.161l1.68-5.754L0,5.791l5.723-.1Z"/><path class="a" d="M7.581,0V11.7L2.9,15.161l1.68-5.754L0,5.791l5.723-.1Z"/></g></svg>); 
     }
      else if(ratings>=4.8 && ratings<5)
     arr.push(<img src={star_4_8}  />); 
     else if(ratings==="5")
     arr.push(<img src={lp_5_star}  />);


    
    console.log(ratings);
    return arr;
  }
  static getNotRatedStars(ratings) {
    console.log("getNotRatedStars")
    var arr = [];
    for (let i = 0; i < 5 - ratings; i++) {
      arr.push(
        <span style={{ color: "LightGray", fontSize: 14 }}>&#9733;</span>
      );
    }
    return [];
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  static getNewTag() {
    return (
      <div className="NewTag">
        <subText style={{ fontSize: 10, fontWeight: "bold", color: "white" }}>
          NEW
        </subText>
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  static getAppLogo(height, padding) {
    return (
      <img
        src={require("../../../assets/logo.png")}
        alt=""
        style={{
          height: height,
          paddingLeft: padding,
        }}
      ></img>
    );
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  static getMenuBar(items, callback) {
    return (
      <div className="LandingMenuBar">
        {this.getAllMenuButtons(items, callback)}
      </div>
    );
  }
  static getAllMenuButtons(items, callback) {
    var arr = [];
    for (let i = 0; i < items.length; i++) {
      arr.push(ComponentManager.getMenuButton(items[i], callback));
    }
    return arr;
  }
  static getMenuButton(title, callback, selected = false) {
    return (
      <div>
        <button onClick={() => callback(title)} className="HeaderMenuItem">
          {title}
        </button>
        {selected && (
          <div style={{ width: "100%", height: 2, backgroundColor: "black" }} />
        )}
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}
