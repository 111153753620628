import React, { Component } from "react";
import "../../common/css/Common.css";
import "../css/ProductDetails.css";
import axios from "axios";

import ComponentManager from "../managers/ComponentManager";
import CommonComponentManager from "../../common/managers/ComponentManager";
import { HOST } from "../../../../Constants";

export default class ProductDetailsScreen extends Component {
  arr_storeImages = [
    require("../../../assets/storeImages/store_img_2.png"),
    require("../../../assets/storeImages/store_img_1.png"),
    require("../../../assets/storeImages/store_img_4.png"),
    require("../../../assets/storeImages/store_img_5.png"),
    require("../../../assets/storeImages/store_img_6.png"),
    require("../../../assets/storeImages/store_img_7.png"),
  ];
  productDetails = {
    productCategory: "Account Software App",
    madeBy: "Deskera",
    addedYear: "2019",
    countries:
      "Australia, Canada, Global, Hong Kong, Indonesia, Ireland, Malaysia, New Zealand, Philippines, Singapore, South Africa, United Kingdom, United States",
    languagues: "English, Hindi",
    support: "Setup walkthough",
    about:
      "Deskera is collaborating with BreadWinner, a CRM, online marketing, sales and customer service growth platform to deliver the best end-to-end customer journey possible. Deskera and BreadWinner integrate together for end-to-end visibility into your customers journey, from stranger to paid customer invoice, all in one place. Using Deskera and BreadWinner helps nurture those leads your website attracts, so you can start building better relationships with your prospects and customers through personalised conversations. The Deskera to BreadWinner integration ensures your Deskera contacts are available in BreadWinner CRM for all your inbound sales processes, and keeps them up-to-date if anything changes in Deskera. This enables you to let your customers pay your Deskera- issued invoices with their credit cards, and for your business to accept card payments with ease.The payment status will be reflected in both your CardUp and Deskera accounts, syncing up both of your favourite platforms.Through this integration, you will be able to provide your customers with a more convenient way of making business payments to your company.Your business will also be able to accept card payments from your customers with no fees or set up required.",
    aboutWithDeskera:
      "We use the email address as an identifier on your Deskera Contact to create a new (or update an existing) contact within BreadWinner. Once this initial integration has completed, any change to your contacts in Deskera will be reflected in BreadWinner CRM automatically, now including the primary contact phone number and if the contact is a customer or supplier. A set of default mappings ensure that the information contained within your Deskera contacts is set to the correct place in BreadWinner.",
  };

  constructor(props) {
    super(props);
    this.state = {
      storeImagesOnTop: false,
      arr_storeImages: this.arr_storeImages,
      storeImage: this.arr_storeImages[1],
      productDetails: this.productDetails,
    };
  }

  componentWillMount() {
    this.fetchAppDetails();
  }

  fetchAppDetails() {
    const { selectedApp } = this.props;
    return axios
      .get(HOST + "/v1/anonymous/marketplace/getApp?id=" + selectedApp.id)
      .then(
        (res) => {
          res.data.productCategory = "Account Software App";
          res.data.description = res.data.description.substring(
            1,
            res.data.description.length - 1
          );
          res.data.imageUrl = "data:image/svg+xml;base64," + res.data.logoImg;
          const thumbnails = JSON.parse(res.data.thumbnails);
          if (thumbnails && thumbnails.links && thumbnails.links.length > 0) {
            this.setState({
              arr_storeImages: thumbnails.links,
              storeImage: thumbnails.links[0],
            });
          }
          this.setState({ productDetails: res.data });
        },
        (err) => {
          console.log(err);
        }
      );
  }

  render() {
    const {
      storeImage,
      arr_storeImages,
      productDetails,
      storeImagesOnTop,
    } = this.state;
    const { selectedApp, appAlreadyConnected } = this.props;
    const buttonText = appAlreadyConnected ? "Open" : "Get App";
    return (
      <div className="ProductDetailsMainView" style={{ position: "relative" }}>
        <div style={{ position: "absolute", top: 20, right: 10 }}>
          {CommonComponentManager.getBorderedButton(
            "Close",
            null,
            this.props.onClose
          )}
        </div>
        {ComponentManager.getScreenHeader(selectedApp, productDetails)}
        {storeImagesOnTop &&
          ComponentManager.getStoreImageSectionView(
            storeImage,
            arr_storeImages,
            this.thumbnailTapped
          )}
        <div style={{ marginTop: 20 }} />
        <table>
          <tr style={{ width: "100%" }}>
            <td className="ProductDescriptionView">
              <div className="GetAppButtonTopView">
                {productDetails.setupLink
                  ? CommonComponentManager.getGradientButton(
                      buttonText,
                      "100%",
                      this.getThisAppTapped
                    )
                  : ComponentManager.getComingSoonButton("Coming Soon", "100%")}
              </div>
              {ComponentManager.getProductDescriptionView(
                selectedApp.appTitle,
                productDetails.description,
                productDetails.aboutWithDeskera
              )}
            </td>
            <td className="ProductInfoColumn">
              {ComponentManager.getProductInfoView(
                productDetails,
                this.getThisAppTapped,
                this.exploreAllFeaturesTapped,
                appAlreadyConnected
              )}
            </td>
          </tr>
        </table>
        <div style={{ marginTop: 40 }} />
        {!storeImagesOnTop &&
          ComponentManager.getStoreImageSectionView(
            storeImage,
            arr_storeImages,
            this.thumbnailTapped
          )}
        <div style={{ marginTop: 40 }} />
      </div>
    );
  }

  thumbnailTapped = (index) => {
    const { arr_storeImages } = this.state;
    this.setState({ storeImage: arr_storeImages[index] });
  };

  getThisAppTapped = () => {
    const { productDetails } = this.state;
    window.open(productDetails.setupLink);
  };
  exploreAllFeaturesTapped = () => {
    window.open("https://breadwinner.com/xero-salesforce/pricing/");
  };
}
