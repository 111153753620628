import axios from 'axios';
import { HOST } from "../../../../Constants";

export const getAllGroups = () => {
  return axios.get(HOST + "/v1/anonymous/marketplace/group").then(res => {
    return res.data;
  }, err => {
    console.log(err);
  });
}

export const getAllApps = (selectedGroup) => {
  return axios.get(HOST + "/v1/anonymous/marketplace").then(res => {
    res.data.forEach((obj, index) => {
      res.data[index].category = res.data[index].groupResponseDto ? res.data[index].groupResponseDto.name : "";
      if(obj.rating!==null && obj.rating!==undefined)
      {
      res.data[index].noOfReviews = Math.trunc(Number(obj.rating)*10)/10;
      }
       // console.log(JSON.stringify(obj),"This is the object");
      res.data[index].price = "FREE";
      res.data[index].img = "data:image/svg+xml;base64," + res.data[index].logoImg;
    });
    return res.data;
  }, err => {
    console.log(err);
  });
};

export const getAllConnectedApps = () => {
  return axios.get(HOST + "/v1/marketplace/getConnectedApp", {withCredentials: true}).then(res => {
    return res.data;
  }, err => {
    return [];
  });
};

export const searchApps = (searchText) => {
  return axios.get(HOST + "/v1/anonymous/marketplace/search?search=" + searchText).then(res => {
      res.data.content.forEach((obj, index) => {
        res.data.content[index].category = res.data.content[index].groupResponseDto ? res.data.content[index].groupResponseDto.name : "";
        if(obj.rating!==null && obj.rating!==undefined)
        {
        res.data.content[index].noOfReviews = Math.trunc(Number(obj.rating)*10)/10;
        }
        res.data.content[index].price = "FREE";
        res.data.content[index].img = "data:image/svg+xml;base64," + res.data.content[index].logoImg;
      });
      return res.data.content;
    }, err => {
      console.log(err);
    });
}

export const getAppsByGroup = (selectedGroup) => {
  return axios.get(HOST + "/v1/anonymous/marketplace/group/"+selectedGroup.id).then(res => {
      res.data.forEach((obj, index) => {
        res.data[index].category = res.data[index].groupResponseDto ? res.data[index].groupResponseDto.name : "";
        if(obj.rating!==null && obj.rating!==undefined)
        {
        res.data[index].noOfReviews = Math.trunc(Number(obj.rating)*10)/10;
        }
        res.data[index].price = "FREE";
        res.data[index].img = "data:image/svg+xml;base64," + res.data[index].logoImg;
      });
      return res.data;
    }, err => {
      console.log(err);
    });
}

