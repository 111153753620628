export const layout = {
  horizontalPadding: 20,
  sectionSpacing: 24,
};

export const HOST = process.env.REACT_APP_BASE_URL;
//export const HOST = "https://api-dev.deskera.xyz";

export const links = {
  ABOUT_US : "https://www.deskera.com/deskera-about/",
  APP_STORE : "https://apps.apple.com/us/app/desk-business-and-accounting/id1463523833?ls=1",
  GOOGLE_PLAY : "https://play.google.com/store/apps/details?id=com.deskera.desk&fbclid=IwAR1hNV5PKV63CTk5lj7cvT00rXapZSI6FOqfCwqLZFtaPDPZIzbgaugv--Y",
  SIGN_UP : "https://www.deskera.com/sign-up/",
  CONTACT_US: "https://www.deskera.com/deskera-contact/",
  GETTING_STARTED: "https://go.deskera.com",
}
export const theme = {
  borderRadius: {
    large: 20,
    medium: 15,
    small: 11,
  },
  colors: {
    primary: "white",
    secondary: "#F8F8FF",
    tertiary: "#DCDCDC",
    background: "white",
    surface: "rgba(240, 240, 245, 1)",
    branding: "rgba(220, 60, 75, 1)",
    gray: "gray",
    blue: "#4169E1",
    green: "#32CD32",
    red: "#DC143C",
    destructive: "red",
    highlighted: "gray",
    line: "#E7E7F4",
    text: {
      primary: "black",
      secondary: "gray",
      tertiary: "rgba(180, 180, 180, 1)",
    },
  },
  fonts: {
    primary: "System",
  },
  fontSizes: {
    navigation: 35,
    header: 30,
    sectionHeader: 20,
    button: {
      small: 12,
      regular: 14,
      medium: 17,
    },
    large: 25,
    regular: 18,
    medium: 15,
    small: 13,
    extraSmall: 11,
  },
};
